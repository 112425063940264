import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import aboutuswhowearehero from "../images/about-us-who-we-are-hero.jpg"

import "../scss/about.scss"
import "../scss/buttons.scss"

const SeoDownloadPage = ({ data }) => {
  const metadescription =
    "Next&Co are an independent digital and traditional performance marketing agency, visit our site or call 1300 191 950."
  const metatitle = "SEO whitepaper - Next&Co"
  const metaurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription} />
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <div className="about-us-page">
        <Layout>
          <section
            id="row-two"
            style={{
              backgroundImage: `url(${aboutuswhowearehero})`,
              backgroundPosition: `center center`,
              backgroundRepeat: `no-repeat`,
              backgroundSize: `cover`,
            }}
          >
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h2 className="col-one-heading">
                      Download our Buyers Guide to Search Engine Optimisation
                      (SEO)
                    </h2>
                  </div>
                  <Link
                    id="seo-whitepaper-download"
                    className="btn"
                    to="https://mcusercontent.com/775eed935c76012f3c33a0f52/files/03b4b83c-1545-4347-8ee6-826b2c027d93/SEO_2020_V03.pdf"
                  >
                    Click here to download
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    </>
  )
}

export default SeoDownloadPage

export const query = graphql`
  query {
    file(relativePath: { eq: "about-herosection-image.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
